function calculationInit() {
  const currentSlider = document.querySelector('.calculation__slides-counter-current')
  const totalSlides = document.querySelector('.calculation__slides-counter-total')
  const inputs = document.querySelectorAll('.calculation input')
  const calculationSlideGalleryItems = document.querySelectorAll('.calculation__slide-gallery-item')
  const nextBtn = document.querySelector('.calculation__slider .swiper-navigation__btn--next')
  const prevBtn = document.querySelector('.calculation__slider .swiper-navigation__btn--prev')

  const data = {
    typeOfConstruction: null,
    material: null,
    width: null,
    length: null,
    location: null,
    floors: null,
    email: null
  }

  const setNextBtnIsActive = () => {
    nextBtn.classList.remove('swiper-navigation__btn--disabled')
  }

  const setNextBtnIsDisabled = () => {
    nextBtn.classList.add('swiper-navigation__btn--disabled')
  }

  calculationSlideGalleryItems.forEach(btn => {
    btn.addEventListener('click', () => {
      btn.classList.add('is-active')
      data.typeOfConstruction = btn.querySelector('.calculation__slide-gallery-item-title').textContent
      setNextBtnIsActive()

      calculationSlideGalleryItems.forEach(otherBtn => {
        if (btn !== otherBtn) {
          otherBtn.classList.remove('is-active')
        }
      })
    })
  })

  const swiper = new Swiper('.calculation__slider', {
    allowTouchMove: false,
    navigation: {
      nextEl: '.calculation__slider .swiper-navigation__btn--next',
      prevEl: '.calculation__slider .swiper-navigation__btn--prev',
    },
    on: {
      init: function (swiper) {
        currentSlider.textContent = swiper.activeIndex + 1
        totalSlides.textContent = swiper.slides.length
      },
      slideChange: function (swiper) {
        currentSlider.textContent = swiper.activeIndex + 1

        if (swiper.activeIndex === swiper.slides.length - 2) {
          nextBtn.querySelector('span').textContent = 'узнать стоимость фундамента'
        } else {
          nextBtn.querySelector('span').textContent = 'Далее'
        }

        if (swiper.activeIndex === swiper.slides.length - 1) {
          nextBtn.style.display = 'none';
          prevBtn.style.display = 'none';

          sendData()
        }
      },
      slidePrevTransitionStart: function (swiper) {
        setNextBtnIsActive()
      },
      slideNextTransitionStart: function (swiper) {
        setNextBtnIsDisabled()
      },
    },
  })

  inputs.forEach(input => {
    input.addEventListener('change', () => {
      data[input.name] = input.value
      setNextBtnIsActive()
    })

    if (input.type === 'number') {
      const form = input.closest('form')
      const reduce = form.querySelector('.reduce')
      const increase = form.querySelector('.increase')

      reduce.addEventListener('click', () => {
        if (input.value > 0) {
          input.value = +input.value - 1
          data[input.name] = input.value

          if (+input.value === 0) {
            setNextBtnIsDisabled()
          }
        }
      })

      increase.addEventListener('click', () => {
        input.value = +input.value + 1
        data[input.name] = input.value

        if (input.value) {
          setNextBtnIsActive()
        } else {
          setNextBtnIsDisabled()
        }
      })
    }
  })

  const sendData = () => {
    console.log('Отправляем данные на сервер: ', data)
  }
}

export { calculationInit }
