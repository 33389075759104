function initCatalog() {
  const catalog = document.querySelector('.catalog');

  if (catalog) {
    const catalogFilters = document.querySelector('.catalog__filters');
    const catalogFiltersBtn = document.querySelector('.catalog__action-btn--filters');
    const catalogFiltersClose = document.querySelector('.catalog__filters-close');

    if (catalogFiltersBtn) {
      catalogFiltersBtn.addEventListener('click', () => {
        catalogFilters.classList.add('is-active');
        document.body.classList.add('scroll-lock');
      });
    }

    if (catalogFiltersClose) {
      catalogFiltersClose.addEventListener('click', () => {
        catalogFilters.classList.remove('is-active');
        document.body.classList.remove('scroll-lock');
      });
    }
  }
}

export { initCatalog }
