function initTabsSelect() {
  const tabsWrappers = document.querySelectorAll('.tabs-wrapper');

  if (tabsWrappers.length) {
    tabsWrappers.forEach((wrapper) => {
      const tabsWrapperBtn = wrapper.querySelector('.tabs-wrapper__btn');
      const tabsItems = wrapper.querySelectorAll('.tabs__item');

      if (tabsWrapperBtn) {
        tabsWrapperBtn.addEventListener('click', () => {
          wrapper.classList.toggle('is-active');
        });

        tabsItems.forEach((item) => {
          item.addEventListener('click', () => {
            wrapper.classList.remove('is-active');
            tabsWrapperBtn.textContent = item.textContent;
          });
        });
      }
    })
  }
}

export { initTabsSelect }
