function initHeader() {
  const headerMenuSwitch = document.querySelector('.header__menu-switch');

  if (headerMenuSwitch) {
    const headerMenuSwitchInput = headerMenuSwitch.querySelector('input');

    if (localStorage.getItem('theme') === 'light') {
      headerMenuSwitchInput.checked = true;
      document.body.classList.add('light');
    }

    headerMenuSwitchInput.addEventListener('change', () => {
      headerMenuSwitchInput.checked
        ? document.body.classList.add('light')
        : document.body.classList.remove('light');

      localStorage.setItem('theme', headerMenuSwitchInput.checked ? 'light' : 'dark');
    });
  }


  const headerMenuBurgerBtn = document.querySelector('.header__menu-burger-btn');
  const headerMenuBurgerMenu = document.querySelector('.header__menu-burger-menu');
  const headerMenuBurgeMenuClose = document.querySelector('.header__menu-burger-menu-close');

  if (headerMenuBurgerBtn) {
    headerMenuBurgerBtn.addEventListener('click', () => {
      headerMenuBurgerMenu.classList.toggle('is-active');
    });

    headerMenuBurgeMenuClose.addEventListener('click', () => {
      headerMenuBurgerMenu.classList.remove('is-active');
    });

    document.addEventListener('click', (evt) => {
      if (!headerMenuBurgerMenu.contains(evt.target) && !headerMenuBurgerBtn.contains(evt.target)) {
        headerMenuBurgerMenu.classList.remove('is-active');
      }
    });
  }
}

export {initHeader}
