function initDiscounts() {
  const discounts = document.querySelector('.discounts');

  if (discounts) {
    const discountsSlider = new Swiper('.discounts__slider', {
      slidesPerView: 1,
      spaceBetween: 40,

      pagination: {
        el: '.discounts__slider .swiper-pagination',
      },
    });
  }
}

export {initDiscounts}
