function initProducts() {
  const products = document.querySelector('.products');

  if (products) {
    const productsSlider = new Swiper('.products__slider', {
      slidesPerView: 4,
      spaceBetween: 8,
      breakpoints: {
        0: {
          slidesPerView: 1.2,
        },
        1280: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: '.swiper-navigation__btn--next',
        prevEl: '.swiper-navigation__btn--prev',
      },
    });
  }
}

export {initProducts}
