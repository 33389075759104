function initGallery() {
  const gallery = document.querySelector('.gallery');
  const gallerySliders = document.querySelectorAll('.gallery__sliders');

  if (gallery) {
    gallerySliders.forEach((el) => {
      const mainSlider = el.querySelector('.gallery__main-slider');
      const thumbsSlider = el.querySelector('.gallery__thumbs-slider');
      const navigation = el.querySelector('.gallery__sliders-navigation');
      const navigationNext = navigation.querySelector('.swiper-navigation__btn--next');
      const navigationPrev = navigation.querySelector('.swiper-navigation__btn--prev');

      const galleryThumbsSlider = new Swiper(thumbsSlider, {
        slidesPerView: 4,
        spaceBetween: 8,

        breakpoints: {
          0: {
            direction: 'horizontal',
          },
          1280: {
            direction: 'vertical',
          },
        },
      });

      const galleryMainSlider = new Swiper(mainSlider, {
        slidesPerView: 1,
        spaceBetween: 8,

        thumbs: {
          swiper: galleryThumbsSlider,
        },

        navigation: {
          nextEl: navigationNext,
          prevEl: navigationPrev,
        },
      });
    })
  }
}

export {initGallery}
